import * as React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Home from "./components/pages/Home";
import Portfolio from "./components/pages/Portfolio";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import NotFound from "./components/pages/NotFound"; // Import NotFound component
import Header from "./components/ui/Header";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./util/Theme";
import { GlobalStyles, SiteContainer } from "./util/Styles";
import LoadingLogo from "./components/ui/LoadingLogo";
import { config } from "./config/config";
import { Routes as WebsiteRoutes } from "./util/Routes";
import Cursor from "./components/ui/Cursor";
import Footer from "./components/ui/Footer";
import CookieConsent from "./components/ui/CookieConsent";
import { Corner } from "./components/ui/ScrollToTop";
import AOS from "aos";
import "./util/styling.css";
import "aos/dist/aos.css";
import i18n from "./i18n/i18n";
import { supportedLanguages } from "./util/Utils";
import ReactGA from "react-ga4";

const PageTransitionDuration = 500;

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Create separate refs for each page transition
  const nodeRef = React.useRef(null);

  const [theme, setTheme] = React.useState<"light" | "dark">(() => {
    if (!localStorage.getItem("theme")) {
      localStorage.setItem("theme", "dark");
    }
    const storedTheme = localStorage.getItem("theme");
    return storedTheme ? (storedTheme as "light" | "dark") : "dark";
  });

  const [showLogo, setShowLogo] = React.useState(true);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, config.animation.loadingLogoDuration);

    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    // On first load, initialize GA if the user has already consented
    if (localStorage.getItem("analyticsEnabled") === "true") {
      ReactGA.initialize("G-DWTQ190ZPC");
      ReactGA.send({ hitType: "pageview", page: location.pathname });
    }
  }, []);

  React.useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const lang = pathSegments[1];

    if (lang === "" || !supportedLanguages.includes(lang)) {
      i18n.changeLanguage("en");
      navigate("/en/");
    } else {
      i18n.changeLanguage(lang);
    }

    const timer = setTimeout(() => {
      // Track page view
      if (localStorage.getItem("analyticsEnabled") === "true") {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
      }

      // Scroll to top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, PageTransitionDuration);

    return () => clearTimeout(timer);
  }, [location]);

  React.useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Cursor />
      {showLogo && <LoadingLogo />}
      <Header toggleTheme={toggleTheme} />
      <Corner />
      <SwitchTransition>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={PageTransitionDuration}
          nodeRef={nodeRef}
          unmountOnExit
        >
          <div ref={nodeRef}>
            <SiteContainer>
              <Routes location={location}>
                <Route path={WebsiteRoutes.ROOT} element={<Home />} />
                <Route path={WebsiteRoutes.PORTFOLIO} element={<Portfolio />} />
                <Route path={WebsiteRoutes.BIO} element={<About />} />
                <Route path={WebsiteRoutes.CONTACT} element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </SiteContainer>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Footer data-aos="fade-up" data-aos-delay="200" />
      <CookieConsent />
    </ThemeProvider>
  );
};

export default App;
