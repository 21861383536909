import { useTranslation } from "react-i18next";
import { SendMailButton } from "./SendMailButton";
import { EmailButtonWrapper } from "../../util/Styles";

export const EmailButton = () => {
  const { t } = useTranslation();

  return (
    <EmailButtonWrapper>
      <p>{t("contact.sendUsEmail")}</p>
      <SendMailButton />
    </EmailButtonWrapper>
  );
};
