import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormContainer, FormField, TextAreaField, Button } from "../../util/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCheckCircle, faWarning } from "@fortawesome/free-solid-svg-icons";
import { API } from "../../network/API";
import { useTranslation } from "react-i18next";

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const [submitStatus, setSubmitStatus] = React.useState<"idle" | "loading" | "success" | "error">("idle");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().max(50, t("contact.form.error.nameMax")).required(t("contact.form.error.nameRequired")),
      email: Yup.string().email(t("contact.form.error.invalidEmail")).required(t("contact.form.error.emailRequired")),
      message: Yup.string()
        .max(1500, t("contact.form.error.messageMax"))
        .required(t("contact.form.error.messageRequired")),
    }),
    onSubmit: async (values) => {
      setSubmitStatus("loading");
      try {
        await API.postContact(values);
        setSubmitStatus("success");
        formik.resetForm();
      } catch (error) {
        console.log(error);
        setSubmitStatus("error");
      }
    },
  });

  return submitStatus === "success" ? (
    <div>
      <div
        style={{
          display: "flex",
          gap: 10,
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 40 }} />
        {t("contact.form.success.line1")}
        <br />
        {t("contact.form.success.line2")}
      </div>
      <Button onClick={() => setSubmitStatus("idle")}>{t("contact.form.success.resetButton")}</Button>
    </div>
  ) : (
    <>
      <h2 className="font-hairline">{t("contact.formTitle")}</h2>
      <FormContainer onSubmit={formik.handleSubmit}>
        <div className="responsive-flex" style={{ display: "flex", gap: 50 }}>
          <FormField style={{ flexGrow: 1 }}>
            <div style={{ height: 20, display: "block" }}>
              {formik.values.name && <label htmlFor="name">{t("contact.form.name")}</label>}
            </div>
            <input
              id="name"
              name="name"
              type="text"
              placeholder={t("contact.form.namePlaceholder")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}
          </FormField>
          <FormField style={{ flexGrow: 1 }}>
            <div style={{ height: 20, display: "block" }}>
              {formik.values.email && <label htmlFor="email">{t("contact.form.email")}</label>}
            </div>
            <input
              id="email"
              name="email"
              type="email"
              placeholder={t("contact.form.emailPlaceholder")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
          </FormField>
        </div>
        <FormField>
          <div style={{ height: 20, display: "block" }}>
            {formik.values.message && <label htmlFor="message">{t("contact.form.message")}</label>}
          </div>
          <TextAreaField
            id="message"
            name="message"
            placeholder={t("contact.form.messagePlaceholder")}
            rows={4}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.message}
          />
          {formik.touched.message && formik.errors.message ? <div>{formik.errors.message}</div> : null}
        </FormField>
        <Button style={{ width: "fit-content" }} type="submit" disabled={submitStatus === "loading"}>
          {submitStatus === "loading" ? t("contact.form.submitting") : t("contact.form.submit")}
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        {submitStatus === "error" && (
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              padding: 10,
              background: "red",
              borderRadius: 10,
              marginTop: 10,
            }}
          >
            <FontAwesomeIcon icon={faWarning} />
            {t("contact.form.error.submitError")}
          </div>
        )}
      </FormContainer>
    </>
  );
};

export default ContactForm;
