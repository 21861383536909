import React, { useState } from "react";
import { Button, CloseButton, CookieConsentWrapper, ModalContainer, ModalWrapper } from "../../util/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const CookieConsent = () => {
  const [consent, setConsent] = useState(localStorage.getItem("cookieConsent"));
  const [showModal, setShowModal] = useState(false);
  const [allowAnalytics, setAllowAnalytics] = useState(false);

  const { t } = useTranslation();

  const initializeGoogleAnalytics = () => {
    if (!(window["ga-disable-G-DWTQ190ZPC"] ?? false)) {
      ReactGA.initialize("G-DWTQ190ZPC");
    }
  };

  const handleConsent = (type: string) => {
    localStorage.setItem("cookieConsent", type);
    setConsent(type);
    setShowModal(false);

    if (type === "accepted" || (type === "custom" && allowAnalytics)) {
      // Enable all cookies or only allow analytics based on custom settings
      initializeGoogleAnalytics();
      localStorage.setItem("analyticsEnabled", "true");
      // Track page view manually
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    } else {
      // Only essential cookies should be allowed
      // Clear any cookies or disable analytics if needed
      localStorage.removeItem("analyticsEnabled"); // Example of clearing analytics preference
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowAnalytics(event.target.checked);
  };

  const openModal = () => {
    setShowModal(true);
  };

  if (consent) {
    return null; // Don't show the banner if consent is already given or declined
  }

  return (
    <>
      <CookieConsentWrapper className="responsive-flex">
        <p>{t("screen.cookieConsent.text")}</p>
        <div style={{ display: "flex", gap: 5 }}>
          <CloseButton success small onClick={() => handleConsent("accepted")}>
            {t("screen.cookieConsent.acceptButton")}
          </CloseButton>
          <CloseButton small onClick={() => handleConsent("declined")}>
            {t("screen.cookieConsent.declineButton")}
          </CloseButton>
          <CloseButton small onClick={openModal}>
            {t("screen.cookieConsent.customizeButton")}
          </CloseButton>
        </div>
      </CookieConsentWrapper>

      {showModal && (
        <ModalWrapper>
          <ModalContainer>
            <h2>{t("screen.cookieConsent.modalTitle")}</h2>
            <p>{t("screen.cookieConsent.modalDescription")}</p>
            <div>
              <label>
                <input type="checkbox" disabled checked /> {t("screen.cookieConsent.necessaryCookies")}
              </label>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={allowAnalytics}
                  onChange={handleCheckboxChange}
                  aria-label={t("screen.cookieConsent.analyticsCookies")}
                />{" "}
                {t("screen.cookieConsent.analyticsCookies")}
              </label>
            </div>
            <div style={{ display: "flex", gap: 15, marginTop: 20 }}>
              <CloseButton small onClick={() => setShowModal(false)}>
                {t("screen.cookieConsent.closeButton")}
              </CloseButton>
              <Button small onClick={() => handleConsent("custom")}>
                {t("screen.cookieConsent.saveButton")}
                <FontAwesomeIcon icon={faCheckCircle} />
              </Button>
            </div>
          </ModalContainer>
        </ModalWrapper>
      )}
    </>
  );
};

export default CookieConsent;
