import React from "react";
import { useTranslation } from "react-i18next";
import { BackgroundImage, PageContainer } from "../../util/Styles";
import MasonryGrid from "../ui/MasonaryGrid";
import { projects } from "../../util/ProjectsData";
import portfolioBgLight from "../../assets/images/portfolio-bg-light.webp";
import portfolioBgDark from "../../assets/images/portfolio-bg-dark.webp";
import { Promo } from "../ui/Promo";
import { Meta } from "../../config/helmet";

const Portfolio: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Meta page="contact" />
      <PageContainer>
        <BackgroundImage
          src={localStorage.getItem("theme") === "dark" ? portfolioBgDark : portfolioBgLight}
          alt={t("portfolio.title")}
        />
        <h1 style={{ paddingTop: 40, textAlign: "center" }}>{t("portfolio.title")}</h1>

        <MasonryGrid projects={projects} hasContactLink />
        <Promo />
      </PageContainer>
    </>
  );
};

export default Portfolio;
