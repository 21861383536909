import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContactButton, CornerContainer, IconButton } from "../../util/Styles";
import { Routes } from "../../util/Routes";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { CreateNavUrl } from "../../util/Utils";
import BackgroundMusic from "./BackgroundMusic";
import { config } from "../../config/config";

export const Corner = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // State to track whether the button is visible based on scroll position
  const [isVisible, setIsVisible] = useState(false);

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // Show the button only if scrolled down past a certain point (e.g., 100px)
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <CornerContainer>
      {/* IconButton now wraps the click event for the entire button, not just the icon */}
      <IconButton
        onClick={scrollToTop}
        style={{
          width: isVisible ? 50 : 0,
          opacity: isVisible ? 1 : 0,
          fontSize: isVisible ? 16 : 0,
          padding: isVisible ? 6 : 0,
        }}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </IconButton>
      {!location.pathname.includes("/contact") && (
        <ContactButton to={CreateNavUrl(Routes.CONTACT)}>{t("contact.title")}</ContactButton>
      )}

      {config.modules.backgroundMusic && <BackgroundMusic />}
    </CornerContainer>
  );
};
