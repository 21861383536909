import {
  project02,
  project03,
  project04,
  project05,
  project06,
  project07,
  project08,
  project09,
  projectAndishia,
  projectArakadres,
  projectNorthAndSouth,
  projectToWhite,
} from "./Projects";

export interface Project {
  slug: string; // Unique slug for URL
  images: string[];
  thumbnail: string;
  disc?: string;
  title: string;
  description: string;
  category: string;
}

export const projects: Project[] = [
  projectArakadres,
  project02,
  project03,
  project04,
  project05,
  project06,
  project07,
  project09,
  projectToWhite,
  project08,
  projectAndishia,
  projectNorthAndSouth,
];
