// components/pages/NotFound.tsx
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../util/Styles";

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
`;

const NotFoundTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
`;

const NotFoundMessage = styled.p`
  font-size: 1.2rem;
  margin-bottom: 40px;
`;

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const lang = location.pathname.split("/")[1];

  return (
    <NotFoundContainer>
      <NotFoundTitle>{t("404.title", "Page Not Found")}</NotFoundTitle>
      <NotFoundMessage>{t("404.message", "Sorry, the page you are looking for does not exist.")}</NotFoundMessage>
      <Button onClick={() => navigate(`/${lang || "en"}`)}>{t("404.backHome", "Go Back Home")}</Button>
    </NotFoundContainer>
  );
};

export default NotFound;
