import project01img01 from "../assets/images/projects/project01/01.webp";
import project01img02 from "../assets/images/projects/project01/02.webp";
import project01img03 from "../assets/images/projects/project01/03.webp";
import project01img04 from "../assets/images/projects/project01/04.webp";
import project01img05 from "../assets/images/projects/project01/05.webp";
import project01Thumbnail from "../assets/images/projects/project01/thumbnail.jpg";
import project01Disc from "../assets/images/projects/project01/disc.jpg";
import project02img01 from "../assets/images/projects/project02/01.webp";
import project02img02 from "../assets/images/projects/project02/02.webp";
import project02img03 from "../assets/images/projects/project02/03.webp";
import project02img04 from "../assets/images/projects/project02/04.webp";
import project02img05 from "../assets/images/projects/project02/05.webp";
import project02Thumbnail from "../assets/images/projects/project02/thumbnail.jpg";
import project02Disc from "../assets/images/projects/project02/disc.jpg";
import project03img01 from "../assets/images/projects/project03/01.webp";
import project03img02 from "../assets/images/projects/project03/02.webp";
import project03img03 from "../assets/images/projects/project03/03.webp";
import project03img04 from "../assets/images/projects/project03/04.webp";
import project03img05 from "../assets/images/projects/project03/05.webp";
import project03Thumbnail from "../assets/images/projects/project03/thumbnail.jpg";
import project03Disc from "../assets/images/projects/project03/disc.jpg";
import project04img01 from "../assets/images/projects/project04/01.webp";
import project04img02 from "../assets/images/projects/project04/02.webp";
import project04img03 from "../assets/images/projects/project04/03.webp";
import project04img04 from "../assets/images/projects/project04/04.webp";
import project04img05 from "../assets/images/projects/project04/05.webp";
import project04Thumbnail from "../assets/images/projects/project04/thumbnail.jpg";
import project04Disc from "../assets/images/projects/project04/disc.jpg";
import project05img01 from "../assets/images/projects/project05/01.webp";
import project05img02 from "../assets/images/projects/project05/02.webp";
import project05img03 from "../assets/images/projects/project05/03.webp";
import project05img04 from "../assets/images/projects/project05/04.webp";
import project05img05 from "../assets/images/projects/project05/05.webp";
import project05Thumbnail from "../assets/images/projects/project05/thumbnail.jpg";
import project05Disc from "../assets/images/projects/project05/disc.jpg";
import project06img01 from "../assets/images/projects/project06/01.webp";
import project06img02 from "../assets/images/projects/project06/02.webp";
import project06img03 from "../assets/images/projects/project06/03.webp";
import project06img04 from "../assets/images/projects/project06/04.webp";
import project06img05 from "../assets/images/projects/project06/05.webp";
import project06Thumbnail from "../assets/images/projects/project06/thumbnail.jpg";
import project06Disc from "../assets/images/projects/project06/disc.jpg";
import project07img01 from "../assets/images/projects/project07/01.webp";
import project07img02 from "../assets/images/projects/project07/02.webp";
import project07img03 from "../assets/images/projects/project07/03.webp";
import project07img04 from "../assets/images/projects/project07/04.webp";
import project07Thumbnail from "../assets/images/projects/project07/thumbnail.jpg";
import project07Disc from "../assets/images/projects/project07/disc.jpg";
import project08img01 from "../assets/images/projects/project08/01.webp";
import project08img02 from "../assets/images/projects/project08/02.webp";
import project08img03 from "../assets/images/projects/project08/03.webp";
import project08img04 from "../assets/images/projects/project08/04.webp";
import project08img05 from "../assets/images/projects/project08/05.webp";
import project08Thumbnail from "../assets/images/projects/project08/thumbnail.jpg";
import project08Disc from "../assets/images/projects/project08/disc.jpg";
import project09img01 from "../assets/images/projects/project09/01.webp";
import project09img02 from "../assets/images/projects/project09/02.webp";
import project09img03 from "../assets/images/projects/project09/03.webp";
import project09img04 from "../assets/images/projects/project09/04.webp";
import project09img05 from "../assets/images/projects/project09/05.webp";
import project09Thumbnail from "../assets/images/projects/project09/thumbnail.jpg";
import project09Disc from "../assets/images/projects/project09/disc.jpg";
import toWhiteimg01 from "../assets/images/projects/toWhite/01.webp";
import toWhiteimg02 from "../assets/images/projects/toWhite/02.webp";
import toWhiteimg03 from "../assets/images/projects/toWhite/03.webp";
import toWhiteThumbnail from "../assets/images/projects/toWhite/thumbnail.webp";
import andishiaimg01 from "../assets/images/projects/andishia/01.webp";
import andishiaimg02 from "../assets/images/projects/andishia/02.webp";
import andishiaimg03 from "../assets/images/projects/andishia/03.webp";
import andishiaimg04 from "../assets/images/projects/andishia/04.webp";
import andishiaThumbnail from "../assets/images/projects/andishia/thumbnail.webp";
import andishiaDisc from "../assets/images/projects/andishia/disc.webp";
import northAndSouthimg01 from "../assets/images/projects/northAndSouth/01.webp";
import northAndSouthimg02 from "../assets/images/projects/northAndSouth/02.webp";
import northAndSouthimg03 from "../assets/images/projects/northAndSouth/03.webp";
import northAndSouthimg04 from "../assets/images/projects/northAndSouth/04.webp";
import northAndSouthThumbnail from "../assets/images/projects/northAndSouth/thumbnail.webp";
import northAndSouthDisc from "../assets/images/projects/northAndSouth/disc.webp";

export const projectArakadres = {
  slug: "Arakadres-Mechanical_Human",
  images: [project01img01, project01img02, project01img03, project01img04, project01img05],
  thumbnail: project01Thumbnail,
  disc: project01Disc,
  title: "Arakadres - Mechanical Human",
  description: "Description of Project 1",
  category: "Music Album Cover",
};

export const project02 = {
  slug: "project02",
  images: [project02img01, project02img02, project02img03, project02img04, project02img05],
  thumbnail: project02Thumbnail,
  disc: project02Disc,
  title: "Project 2",
  description: "Description of Project 2",
  category: "Music Album Cover",
};

export const project03 = {
  slug: "project03",
  images: [project03img01, project03img02, project03img03, project03img04, project03img05],
  thumbnail: project03Thumbnail,
  disc: project03Disc,
  title: "Project 3",
  description: "Description of Project 3",
  category: "Music Album Cover",
};

export const project04 = {
  slug: "project04",
  images: [project04img01, project04img02, project04img03, project04img04, project04img05],
  thumbnail: project04Thumbnail,
  disc: project04Disc,
  title: "Project 4",
  description: "Description of Project 4",
  category: "Music Album Cover",
};

export const project05 = {
  slug: "project05",
  images: [project05img01, project05img02, project05img03, project05img04, project05img05],
  thumbnail: project05Thumbnail,
  disc: project05Disc,
  title: "Project 5",
  description: "Description of Project 5",
  category: "Music Album Cover",
};

export const project06 = {
  slug: "project06",
  images: [project06img01, project06img02, project06img03, project06img04, project06img05],
  thumbnail: project06Thumbnail,
  disc: project06Disc,
  title: "Project 6",
  description: "Description of Project 6",
  category: "Music Album Cover",
};

export const project07 = {
  slug: "project07",
  images: [project07img01, project07img02, project07img03, project07img04],
  thumbnail: project07Thumbnail,
  disc: project07Disc,
  title: "Project 7",
  description: "Description of Project 7",
  category: "Music Album Cover",
};

export const project08 = {
  slug: "project08",
  images: [project08img01, project08img02, project08img03, project08img04, project08img05],
  thumbnail: project08Thumbnail,
  disc: project08Disc,
  title: "Project 8",
  description: "Description of Project 8",
  category: "Music Album Cover",
};

export const project09 = {
  slug: "project09",
  images: [project09img01, project09img02, project09img03, project09img04, project09img05],
  thumbnail: project09Thumbnail,
  disc: project09Disc,
  title: "Project 9",
  description: "Description of Project 9",
  category: "Music Album Cover",
};

export const projectToWhite = {
  slug: "to_white",
  images: [toWhiteimg01, toWhiteimg02, toWhiteimg03],
  thumbnail: toWhiteThumbnail,
  title: "To White",
  description: "Description of Project 9",
  category: "Single Track Cover",
};

export const projectAndishia = {
  slug: "andishia",
  images: [andishiaimg01, andishiaimg02, andishiaimg03, andishiaimg04],
  thumbnail: andishiaThumbnail,
  disc: andishiaDisc,
  title: "Andishia",
  description: "Description of Project 9",
  category: "Music Album Cover",
};

export const projectNorthAndSouth = {
  slug: "northAndSouth",
  images: [northAndSouthimg01, northAndSouthimg02, northAndSouthimg03, northAndSouthimg04],
  thumbnail: northAndSouthThumbnail,
  disc: northAndSouthDisc,
  title: "North & South",
  description: "Description of Project 9",
  category: "Music Album Cover",
};
