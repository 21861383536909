import React from "react";
import { LogoContainer } from "../../util/Styles";
import { Logo } from "./Logo";

const LoadingLogo: React.FC = () => (
  <LogoContainer>
    <Logo size={150} />
  </LogoContainer>
);

export default LoadingLogo;
