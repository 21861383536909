import React from "react";
import {
  BackgroundImage,
  HomeTitle,
  PageContainer,
  IconButton,
  HomeTagline,
  HomePromoWrapper,
} from "../../util/Styles";
import MasonryGrid from "../ui/MasonaryGrid";
import { projects } from "../../util/ProjectsData";
import PromoDark from "../../assets/images/promo-dark.webp";
import PromoDarkXs from "../../assets/images/promo-dark-xs.webp";
import PromoLightXs from "../../assets/images/promo-light-xs.webp";
import PromoLight from "../../assets/images/promo-light.webp";
import TitlePatternDark from "../../assets/images/title-pattern-dark.webp";
import TitlePatternLight from "../../assets/images/title-pattern-light.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Promo } from "../ui/Promo";
import { Meta } from "../../config/helmet";
import Clients from "../ui/Clients";
import { Trans, useTranslation } from "react-i18next";
import FAQList from "../ui/Faq";

const Home: React.FC = () => {
  const { t } = useTranslation();

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Meta page="home" />
      <PageContainer>
        <BackgroundImage
          className="hide-xs"
          src={localStorage.getItem("theme") === "dark" ? PromoDark : PromoLight}
          alt={"promo"}
          style={{ top: 0 }}
        />
        <BackgroundImage
          className="only-xs"
          src={localStorage.getItem("theme") === "dark" ? PromoDarkXs : PromoLightXs}
          alt={"promo"}
        />
        <HomePromoWrapper>
          <HomeTitle
            style={{
              backgroundImage: `url(${
                localStorage.getItem("theme") === "dark" ? TitlePatternDark : TitlePatternLight
              })`,
            }}
          >
            {t("home.promo.title")}
          </HomeTitle>
          <HomeTagline>
            <Trans i18nKey="home.promo.tagline1" components={{ strong: <strong /> }} />
            <br />
            <Trans i18nKey="home.promo.tagline2" components={{ strong: <strong /> }} />
          </HomeTagline>
          <IconButton $withanimation="true" onClick={() => scrollToSection("portfolioSection")}>
            <FontAwesomeIcon icon={faArrowDown} />
          </IconButton>
        </HomePromoWrapper>

        <div
          id="portfolioSection"
          style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <h2
            onClick={() => scrollToSection("portfolioSection")}
            style={{ width: "fit-content" }}
            className="cursor-pointer"
          >
            {t("portfolio.title")}
          </h2>
        </div>

        <MasonryGrid projects={projects} hasContactLink />
        <Clients />
        <Promo />
        <FAQList />
      </PageContainer>
    </>
  );
};

export default Home;
