import { config } from "../../config/config";
import { HeaderLogo } from "../../util/Styles";

export const Logo = (props: { hasTitle?: boolean; size?: number }) => {
  return (
    <HeaderLogo size={props.size}>
      <div>
        <img src={config.site.logo} alt={config.site.name} className="header-logo" />
        {config.decoration.logoBackground && (
          <>
            <img className="header-decoration" src={config.decoration.logoBackground} alt="header-logo-decoration" />
            <img className="header-decoration" src={config.decoration.logoBackground} alt="header-logo-decoration" />
          </>
        )}
      </div>
      {props.hasTitle && (
        <div>
          <h1>ZEAM</h1>
          <p>The music visionary</p>
        </div>
      )}
    </HeaderLogo>
  );
};
