import { Helmet, HelmetProvider } from "react-helmet-async";
import { Routes } from "../util/Routes";
import { config } from "./config";
import { CreateNavUrl } from "../util/Utils";
import { useTranslation } from "react-i18next";

interface IMetadata {
  title: string;
  description: string;
  keywords: string;
  canonical: string;
}

export const Meta = (props: { page: string }) => {
  const { t } = useTranslation();

  const metaData: IMetadata = {
    title: "",
    description: "",
    keywords: "",
    canonical: "",
  };

  switch (props.page) {
    case "home":
      metaData.title = t("meta.home.title");
      metaData.description = t("meta.home.description");
      metaData.keywords = t("meta.home.keywords");
      metaData.canonical = CreateNavUrl(Routes.ROOT);
      break;
    case "portfolio":
      metaData.title = t("meta.portfolio.title");
      metaData.description = t("meta.portfolio.description");
      metaData.keywords = t("meta.portfolio.keywords");
      metaData.canonical = CreateNavUrl(Routes.PORTFOLIO);
      break;
    case "about":
      metaData.title = t("meta.about.title");
      metaData.description = t("meta.about.description");
      metaData.keywords = t("meta.about.keywords");
      metaData.canonical = CreateNavUrl(Routes.BIO);
      break;
    case "contact":
      metaData.title = t("meta.contact.title");
      metaData.description = t("meta.contact.description");
      metaData.keywords = t("meta.contact.keywords");
      metaData.canonical = CreateNavUrl(Routes.CONTACT);
      break;
  }

  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: config.site.name,
    url: config.site.url,
    logo: `${config.site.url}/logo.png`,
    description: t("meta.home.description"),
    sameAs: [config.contactInfo.socialLinks.instagram, config.contactInfo.socialLinks.behance],
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "Customer Service",
      email: config.contactInfo.email,
    },
    areaServed: "Global",
  };

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: t("screen.header.home"),
        item: `${config.site.url}${CreateNavUrl(Routes.ROOT)}`,
      },
      {
        "@type": "ListItem",
        position: 2,
        name: t("screen.header.portfolio"),
        item: `${config.site.url}${CreateNavUrl(Routes.PORTFOLIO)}`,
      },
      {
        "@type": "ListItem",
        position: 3,
        name: t("screen.header.about"),
        item: `${config.site.url}${CreateNavUrl(Routes.BIO)}`,
      },
      {
        "@type": "ListItem",
        position: 4,
        name: t("screen.header.contact"),
        item: `${config.site.url}${CreateNavUrl(Routes.CONTACT)}`,
      },
    ],
  };

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="canonical" href={config.site.url + metaData.canonical} />
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:description" content={metaData.description} />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <script type="application/ld+json">{JSON.stringify(websiteSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(breadcrumbSchema)}</script>
      </Helmet>
    </HelmetProvider>
  );
};
