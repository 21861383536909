import React from "react";
import Masonry from "react-masonry-css";
import { Project } from "../../util/ProjectsData";
import { MasonryItem } from "../../util/Styles";
import { ProjectPage } from "../pages/Project";
import { PortfolioClickBait } from "./portfolioClickBait";

interface MasonryGridProps {
  projects: Project[];
  hasContactLink?: boolean; // when set to true, promo boxes are shown
}

// Adjusted breakpoints
const breakpointColumnsObj = {
  default: 2,
  700: 2, // 2 columns for screens wider than 700px
  500: 1, // 1 column for screens narrower than 500px
};

const MasonryGrid: React.FC<MasonryGridProps> = ({ projects, hasContactLink }) => {
  const [selectedProject, setSelectedProject] = React.useState<string | undefined>(undefined);

  return (
    <>
      {selectedProject && <ProjectPage selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {projects.map((project, index) => (
          <React.Fragment key={project.slug}>
            {hasContactLink && index > 0 && index % 3 === 0 && (
              <PortfolioClickBait key={`promo-${index}`} data-aos="fade" />
            )}
            <MasonryItem className="project-thumbnail" key={project.slug} data-aos="fade">
              <div onClick={() => setSelectedProject(project.slug)}>
                <img src={project.thumbnail} alt={project.title} />
                {project.disc && <img src={project.disc} alt={project.title} />}
              </div>
            </MasonryItem>
          </React.Fragment>
        ))}
      </Masonry>
    </>
  );
};

export default MasonryGrid;
