export const Routes = {
  ROOT: `/:lang`,
  PORTFOLIO: `/:lang/portfolio`,
  PROJECT: `/:lang/portfolio/:slug`,
  BIO: `/:lang/bio`,
  CONTACT: `/:lang/contact`,
  AGB: {
    ROOT: `/:lang/agb`,
  },
  PRIVACY: {
    ROOT: `/:lang/privacy`,
  },
  IMPRINT: {
    ROOT: `/:lang/impressum`,
  },
};
