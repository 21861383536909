import React, { useEffect, useRef, useState } from "react";
// Import the audio files
import backgroundMusic01 from "../../assets/audio/backgroundMusic01.mp3";
import backgroundMusic02 from "../../assets/audio/backgroundMusic02.mp3";
import backgroundMusic03 from "../../assets/audio/backgroundMusic03.mp3"; // Add more as needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPause,
  faPlay,
  faVolumeHigh,
  faVolumeMute,
  faForward,
  faRadio,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { AudioPlayerContainer, AudioPlayerWrapper, IconButton } from "../../util/Styles";

const BackgroundMusic: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(0.5); // Initial volume set to 50%
  const [showVolumeSlider, setShowVolumeSlider] = useState<boolean>(false);
  const [currentSongIndex, setCurrentSongIndex] = useState<number>(0); // Track the current song
  const [isOpen, setIsOpen] = useState<boolean>(false); // Default to true to show player on larger screens

  // List of audio files
  const songs = [backgroundMusic01, backgroundMusic02, backgroundMusic03]; // Add your songs here

  // Shuffle to the next song
  const shuffleNextSong = () => {
    let nextSongIndex;
    do {
      nextSongIndex = Math.floor(Math.random() * songs.length);
    } while (nextSongIndex === currentSongIndex); // Ensure a different song is selected

    setCurrentSongIndex(nextSongIndex);
  };

  // Initialize audio object once when component mounts
  useEffect(() => {
    const audio = new Audio(songs[currentSongIndex]);
    audioRef.current = audio;
    audio.volume = volume; // Set initial volume
    audio.controls = false;
    audio.preload = "auto"; // Preload the audio to buffer

    // Event listener to shuffle to the next song when the current one ends
    audio.addEventListener("ended", () => {
      shuffleNextSong();
    });

    // Event listener for playing the audio on first click anywhere on the screen
    const handleClick = () => {
      if (audioRef.current) {
        audioRef.current.play();
        document.removeEventListener("click", handleClick);
        setIsPlaying(true);
      }
    };

    document.addEventListener("click", handleClick);

    // Cleanup function to pause and reset the audio
    return () => {
      document.removeEventListener("click", handleClick);
      audio.removeEventListener("ended", shuffleNextSong);
      if (audioRef.current) {
        audioRef.current.pause(); // Pause the audio when component unmounts
        audioRef.current.currentTime = 0; // Reset the audio to the beginning
      }
    };
  }, [currentSongIndex]); // Re-run when currentSongIndex changes

  // Update volume without recreating audio
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]); // Only runs when volume changes

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
  };

  const toggleVolumeSlider = () => {
    setShowVolumeSlider(!showVolumeSlider);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest(".volume-control")) {
      setShowVolumeSlider(false);
    }
  };

  useEffect(() => {
    if (showVolumeSlider) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showVolumeSlider]);

  // Manually shuffle to the next song
  const handleNext = () => {
    if (audioRef.current) {
      audioRef.current.pause(); // Pause the current song
      shuffleNextSong(); // Shuffle to the next song
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.src = songs[currentSongIndex]; // Set the new song
      if (isPlaying) {
        // Only play if the previous song was playing
        audioRef.current.play().catch((err) => console.error("Error playing audio:", err)); // Play the new song
      }
    }
  }, [currentSongIndex]);

  return (
    <AudioPlayerWrapper $isplaying={isPlaying ? "true" : "false"}>
      <AudioPlayerContainer $isplaying={isPlaying ? "true" : "false"} className={isOpen ? "" : "hide-xs"}>
        <div className="music-icon" onClick={handlePlayPause}>
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
        </div>
        {isPlaying && (
          <>
            <div className="music-icon" onClick={handleNext}>
              <FontAwesomeIcon icon={faForward} />
            </div>
            <div className="volume-control" style={{ display: "inline-block", position: "relative" }}>
              <div className="music-icon" onClick={toggleVolumeSlider}>
                <FontAwesomeIcon icon={volume > 0 ? faVolumeHigh : faVolumeMute} />
              </div>
              {showVolumeSlider && (
                <div className="volume-slider">
                  <input type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} />
                </div>
              )}
            </div>
          </>
        )}
      </AudioPlayerContainer>
      <IconButton className="only-xs" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={isOpen ? faClose : faRadio} />
      </IconButton>
    </AudioPlayerWrapper>
  );
};

export default BackgroundMusic;
