import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { config } from "../config/config";
import "@fontsource/montserrat/";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/800.css";
import "@fontsource/bungee-inline";
import "@fontsource/bungee-outline";
import "@fontsource/bungee-hairline";
import "@fontsource/bungee-shade";

// keyframes
const fadeOut = keyframes`
  0% , 50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  0% , 50% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
`;

const QuickfadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const iconRotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const colorAnimation = (color: string, mainColor: string) => keyframes`
  0% {
    color: ${color};
  }
  50% {
    color: ${mainColor};
  }
  100% {
    color: ${color};
  }
`;

// Global styles
export const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Bungee Inline', sans-serif;
    background: ${({ theme }) => theme.BACKGROUND_COLOR};
    color: ${({ theme }) => theme.FONT_COLOR};
    transition: all 0.25s linear;
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .font-hairline {
    font-family: 'Bungee Hairline', sans-serif;
  }

  .font-montserrat {
    font-family: "Montserrat", sans-serif;
  }

  h1 {
    transform: scaleY(1.3);
    margin-bottom: 50px;
    word-spacing: 9999px;
  }

  
  .responsive-flex {
    display: flex;
    flex-direction: row !important;
  }
  
  /* CURSOR */
  * {
    cursor: none !important;
  }
  .custom-cursor {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: ${({ theme }) => theme.CURSOR_DEFAULT_COLOR};
    border-radius: 0;
    pointer-events: none;
    z-index: 102;
    transform: translate(-50%, -50%);
    transition: width 300ms, height 300ms;
    border-radius: 20px;
  }

  .custom-cursor.pointer {
    width: 30px;
    height: 30px;
    background-color: ${({ theme }) => theme.CURSOR_POINTER_COLOR};
  }

  .custom-cursor.close {
    width: 40px;
    height: 6px;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.CURSOR_POINTER_COLOR};

    &:before {
      content: "";
      width: 40px;
      height: 6px;
      background: ${({ theme }) => theme.CURSOR_POINTER_COLOR};
      position: absolute;
      top: 0;
      left: 10;
      transform: rotate(90deg);
      border-radius: 20px;
    }
  }

  .custom-cursor.input {
    width: 5px;
    height: 30px;
    background-color: ${({ theme }) => theme.CURSOR_INPUT_COLOR};
  }

  .custom-cursor.default {
    background-color: ${({ theme }) => theme.CURSOR_DEFAULT_COLOR};
  }

  /* SCROLLBAR */
  /* Webkit browsers (Chrome, Safari, Edge) */
  ::-webkit-scrollbar {
      width: 10px;
  }

  ::-webkit-scrollbar-track {
      background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.FONT_COLOR}b3; 
      border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
      background-color: ${(props) => props.theme.FONT_COLOR};
  }

  /* Firefox */
  * {
      scrollbar-width: thin; /* Makes the scrollbar thin */
      scrollbar-color: ${(props) => props.theme.FONT_COLOR}b3 transparent; /* White thumb, no track color */
  }

  /* To support rounded corners in Firefox (optional) */
  *::-webkit-scrollbar-thumb {
      border-radius: 10px;
  }

  /* RESPONSIVE */
  @media (min-width: 769px) {
    .only-xs {
      display: none;
    }
  }

  @media (max-width: 768px) {
    body {
      font-size: 14px;
    }

    .responsive-flex {
      flex-direction: column !important;
    }

    .hide-xs {
      display: none !important; 
    }

    .custom-cursor {
      display: none !important;
    }
  }
`;

// Loader
export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.BACKGROUND_COLOR};
  z-index: 101;
  opacity: 1;
  animation: ${css`
    ${fadeOut} ${config.animation.loadingLogoDuration}ms forwards
  `};
`;

// Header
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  @media (max-width: 768px) {
    background: ${(props) => props.theme.BACKGROUND_COLOR};
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${(props) => props.theme.BACKGROUND_COLOR};
    z-index: -1;
    filter: blur(36px);
    top: -14px;
    left: 0;
  }
`;

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: ${({ theme }) => theme.FONT_COLOR};
  width: 100%;
  max-width: ${(props) => props.theme.MAX_SCREEN_WIDTH}px;
`;

export const HeaderLogo = styled.div<{ size?: number }>`
  display: flex; 
  align-items: center;
  gap: 15px;
  position: relative;

  .header-logo {
    width: ${(props) => props.size ?? 55}px;
    height: ${(props) => props.size ?? 55}px;
    border-radius: 60px;
  }

  .header-decoration {
    position: absolute;
    left: -2px;
    top: -2px;
    z-index: -1;
    width: ${(props) => (props.size ? props.size + 5 : 60)}px;
    height: ${(props) => (props.size ? props.size + 5 : 60)}px;
    border-radius: 0;
  }
      
  .header-decoration:nth-child(2) {
    animation: ${css`
      ${rotate} 15s linear infinite;
    `}
  }

  .header-decoration:nth-child(3) {
    animation: ${css`
      ${rotate} 20s linear infinite;
    `}
      opacity: 0.7;
  }

  * {
    color: ${(props) => props.theme.FONT_COLOR};
  }

  h1 {
    font-family: 'Bungee Shade', sans-serif;
    margin: 0;
    margin-bottom: 5px;
    letter-spacing: 5px;
    -moz-transition: all ${config.animation.linkAnimation}ms;
    -webkit-transition: all ${config.animation.linkAnimation}ms;
    -o-transition: all ${config.animation.linkAnimation}ms;
    transition: all ${config.animation.linkAnimation}ms;
  }

  p {
    font-family: 'Bungee Hairline', sans-serif;
    margin: 0;
    font-size: 11px;
  }

  &:hover {
    h1 {
      color: ${({ theme }) => theme.MAIN_COLOR};
      letter-spacing: 8px;
    }
  }
`;

export const Nav = styled.nav<{ $opened: string }>`
  display: flex;
  align-items: center;
  gap: 20px;
  transition: ${config.animation.styleAnimationDuration}ms ease-in-out;

  @media (max-width: 768px) {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.MENU_BACKGROUND_XS};
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    overflow: ${(props) => (props.$opened === "true" ? "initial" : "hidden")};
    border-radius: 0 0 20px 20px;
    max-height: ${(props) => (props.$opened === "true" ? "500px" : 0)};
    opacity: ${(props) => (props.$opened === "true" ? 1 : 0)};
    padding: ${(props) => (props.$opened === "true" ? "30px" : 0)};

    a {
      font-size: 40px;
    }
  }
`;

export const NavLink = styled(Link)<{ selected: boolean }>`
  text-decoration: none;
  color: ${({ selected }) => (selected ? "red" : "inherit")};
  -webkit-transform: skewX(0deg);
  -moz-transform: skewX(0deg);
  -o-transform: skewX(0deg);
  transform: skewX(0deg);
  -moz-transition: all ${config.animation.linkAnimation}ms;
  -webkit-transition: all ${config.animation.linkAnimation}ms;
  -o-transition: all ${config.animation.linkAnimation}ms;
  transition: all ${config.animation.linkAnimation}ms;

  &:hover {
    font-family: ${({ selected }) => (selected ? "inherit" : "'Bungee Hairline', sans-serif")};
    opacity: 0.7;
    -webkit-transform: skewX(-5deg);
    -moz-transform: skewX(-5deg);
    -o-transform: skewX(-5deg);
    transform: skewX(-5deg);
  }

  @media (max-width: 768px) {
    display: block;
    margin: 10px 0;
  }
`;

export const Burger = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  div {
    height: 1px;
    background: ${({ theme }) => theme.FONT_COLOR};
    margin: 3px;
    transition: all 0.3s ease;
  }

  div:nth-of-type(1) {
    width: 15px;
  }

  div:nth-of-type(2) {
    width: 22px;
  }

  div:nth-of-type(3) {
    width: 18px;
  }

  &.open div:nth-child(1) {
    width: 22px;
    transform: rotate(45deg) translate(5px, 5px);
  }

  &.open div:nth-child(2) {
    opacity: 0;
  }

  &.open div:nth-child(3) {
    width: 22px;
    transform: rotate(-45deg) translate(5px, -5px);
  }
`;

// UI
export const IconLink = styled.a`
  font-size: 20px;
  width: 35px;
  height: 35px;
  color: white;
  background: ${({ theme }) => theme.MAIN_COLOR};
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  transition: ${config.animation.styleAnimationDuration}ms;

  &:hover {
    background: #fff;
    color: ${({ theme }) => theme.MAIN_COLOR};
    box-shadow: 1px 1px 19px #d2d2d2;
  }
`;

export const CornerContainer = styled.div`
  position: fixed;
  bottom: 40px;
  left: 60px;
  z-index: 97;
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    left: 0;
  }
`;

export const Button = styled.button<{ small?: boolean }>`
  font-family: "Bungee Inline", sans-serif;
  position: relative;
  margin: 0;
  border: 2px solid ${(props) => props.theme.FONT_COLOR};
  background: ${(props) => props.theme.FONT_COLOR};
  color: ${(props) => props.theme.BACKGROUND_COLOR};
  padding: ${(props) => (props.small ? "10px" : "20px 25px")};
  font-size: ${(props) => (props.small ? "12px" : "16px")};
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;
  overflow: hidden;

  &:after {
    content: "";
    width: 25%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 75%;
    z-index: 1;
    background: ${({ theme }) => theme.MAIN_COLOR}80;
    border-radius: 30px 0 0 30px;
    opacity: 0;
    transition: ${config.animation.linkAnimation}ms;
  }

  &:hover {
    background: ${({ theme }) => theme.BACKGROUND_COLOR};
    color: ${({ theme }) => theme.FONT_COLOR};
    border: 2px solid ${({ theme }) => theme.FONT_COLOR};

    &:after {
      opacity: 1;
      top: 0;
    }
  }
`;

export const CloseButton = styled.button<{ small?: boolean; success?: boolean }>`
  font-family: "Bungee Inline", sans-serif;
  position: relative;
  margin: 0;
  border: none;
  background: ${(props) => (props.success ? "green" : props.theme.GREY)};
  color: ${(props) => props.theme.FONT_COLOR};
  padding: ${(props) => (props.small ? "10px" : "20px 25px")};
  font-size: ${(props) => (props.small ? "12px" : "16px")};
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 300ms;

  &:hover {
    opacity: 0.5;
  }
`;

export const LanguageSelect = styled.select`
  margin: 0 10px;
  padding: 5px 10px;
  border: none;
  background: ${({ theme }) => theme.FONT_COLOR};
  color: ${({ theme }) => theme.BACKGROUND_COLOR};

  @media (max-width: 768px) {
    display: block;
    margin: 10px 0;
  }
`;

export const HeaderActionWrapper = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    justify-content: space-between;
    width: 100%;
  }
`;

export const LanguageContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const CurrentLanguage = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    left: 0;
    bottom: -8px;
    background-color: ${({ theme }) => theme.FONT_COLOR};
  }
`;

const menuFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const LanguageList = styled.ul<{ $isopen: string }>`
  display: ${(props) => (props.$isopen === "true" ? "block" : "none")};
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  top: 28px;
  left: -10px;
  z-index: 96;
  border-radius: 4px;
  overflow: hidden;
`;

export const LanguageItem = styled.li<{ $delay: number }>`
  padding: 8px 12px;
  color: white; /* White text on black background */
  opacity: 0; /* Initially hidden */
  animation: ${menuFadeIn} 0.5s ease forwards;
  animation-delay: ${(props) => props.$delay || 0}ms; /* Apply delay based on index */
  background: black;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #444; /* Slightly lighter black on hover */
  }
`;

// Page container styles
export const SiteContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
  padding: 20px;
  max-width: ${(props) => props.theme.MAX_SCREEN_WIDTH}px;

  @media (max-width: 768px) {
    padding: 10px;
    gap: 45px;
  }
`;

export const BackgroundImage = styled.img<{ $bottom?: string }>`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.$bottom === "true" ? "auto" : "60px")};
  bottom: ${(props) => (props.$bottom === "true" ? "0" : "auto")};
  left: 0;
  z-index: -1;

  @media (max-width: 768px) {
    top: ${(props) => (props.$bottom === "true" ? "auto" : "60px")};
    bottom: ${(props) => (props.$bottom === "true" ? "0" : "auto")};
  }
`;

// Masonry Grid styles
export const MasonryGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  grid-auto-flow: dense; /* Helps to fill gaps */
`;

export const MasonryItem = styled.div`
  position: relative;
  padding: 40px;

  // Thumbnail
  img:nth-child(1) {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; /* Ensures images cover the item area */
    transition: ${config.animation.styleAnimationDuration}ms;
  }

  // Disc
  img:nth-child(2) {
    width: calc(100% - 80px);
    border-radius: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    left: 40px;
    top: 40px;
    transition: ${config.animation.styleAnimationDuration}ms;
  }

  &:hover img:nth-child(1) {
    transform: translateX(-35px);
  }

  &:hover img:nth-child(2) {
    transform: translateX(15%);
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const createFlashing = (color: string) => keyframes`
  0% {
    text-shadow: ${color}69 0px 0px 5px;
  }
  50% {
    text-shadow: ${color}90 0px 0px 10px;
  }
  100% {
    text-shadow: ${color}69 0px 0px 5px;
  }
`;

const createBoxFlashing = (color: string) => keyframes`
  0% {
    box-shadow: ${color}69 0px 0px 5px;
  }
  50% {
    box-shadow: ${color}90 0px 0px 10px;
  }
  100% {
    box-shadow: ${color}69 0px 0px 5px;
  }
`;

const arrowMove = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Home page
export const HomePromoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  text-align: center;

  @media (max-width: 768px) {
    height: 65vh;
  }
`;

export const HomeTitle = styled.h1`
  font-size: 55px;
  word-spacing: normal;
  margin: 0;
  background-size: contain;
  transition: ${config.animation.loadingLogoDuration}ms ease-in-out;
  background-position-x: 0%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  background-clip: text; /* Added for non-WebKit browsers */
  -webkit-text-fill-color: transparent;
  /* Fallback for non-WebKit browsers */
  color: ${(props) => props.theme.FONT_COLOR};
  // text-shadow: ${(props) => props.theme.FONT_COLOR}69 0px 0px 5px;
  animation: ${(props) => css`
    ${createFlashing(props.theme.FONT_COLOR)} ${config.animation.loadingLogoDuration}ms infinite
  `};

  &:hover {
    background-position-x: 5%;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 60px;
  }
`;

export const HomeTagline = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 28px;

  @media (max-width: 768px) {
    margin: 10px;
    font-size: 20px;
    word-spacing: 16px;
  }
`;

export const ClientsWrapper = styled.section`
  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 50px;
  }

  img {
    max-height: 100px;
    max-width: 200px;
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;

    div {
      justify-content: center;
    }

    h2 {
      text-align: center;
    }

    img {
      max-width: 130px;
    }
  }
`;

export const IconButton = styled.button<{ $withanimation?: string }>`
  width: 50px;
  height: 50px;
  font-size: 16px;
  cursor: pointer;
  background: ${(props) => props.theme.BACKGROUND_COLOR}c2;
  color: ${(props) => props.theme.FONT_COLOR};
  border: 1px solid ${(props) => props.theme.FONT_COLOR};
  border-radius: 50px;
  transition: all 0.3s ease-in-out;

  svg {
    animation: ${(props) =>
      props.$withanimation === "true"
        ? css`
            ${arrowMove} ${config.animation.loadingLogoDuration}ms infinite
          `
        : "none"};
  }

  &:hover {
    background-color: ${(props) => props.theme.MAIN_COLOR};
  }
`;

export const ContactTitle = styled.h1`
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const EmailButtonWrapper = styled.div`
  p {
    font-family: "Bungee Hairline";
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ContactEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const ContactButton = styled(Link)`
  font-family:
    "Bungee Inline",
    Sans serif;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;
  background: ${(props) => props.theme.BACKGROUND_COLOR}70;
  color: ${(props) => props.theme.FONT_COLOR};
  border: 1px solid ${(props) => props.theme.FONT_COLOR};
  border-radius: 50px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.MAIN_COLOR};
  }
`;

export const PromoContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  background: ${(props) => props.theme.GREY};
  padding: 30px;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  transition: ${config.animation.styleAnimationDuration}ms;

  h2 {
    font-family: "Bungee Hairline";
    font-size: 36px;
  }

  img {
    width: 64px;
    height: 64px;
    animation: ${css`
      ${iconRotate} 3s ease-in-out infinite;
    `};
  }

  &:hover {
    box-shadow: 0px 0px 20px ${(props) => props.theme.MAIN_COLOR}42;
  }

  @media (max-width: 768px) {
    padding: 15px;

    h3 {
      font-size: 12px;
    }

    h2 {
      font-size: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
`;

export const PortfolioPromoWrapper = styled.div`
  padding: 40px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const PortfolioPromo = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.FONT_COLOR};
`;

// Project Page styles
export const ProjectContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  left: 0px;
  z-index: 98;
  overflow: scroll;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: ${(props) => props.theme.BACKGROUND_COLOR}e6;
  padding: 150px;
  align-items: center;
  animation: ${css`
    ${QuickfadeIn} ${config.animation.styleAnimationDuration}ms
  `};

  button {
    width: fit-content;
    padding: 25px 30px;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  @media (max-width: 768px) {
    padding: 50px 20px;

    button {
      position: fixed;
      bottom: 20px;
      margin-bottom: 0;
    }
  }
`;

export const ProjectTitle = styled.h2`
  margin-bottom: 10px;
`;

export const ProjectCategory = styled.p`
  margin-bottom: 10px;
`;

export const ProjectDescription = styled.p`
  margin-bottom: 20px;
`;

export const ProjectImage = styled.img`
  width: 100%;
  width: 100%;
  height: auto;
  display: block;
  margin: 0;
`;

// Form
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
`;

export const FormField = styled.div`
  margin-bottom: 1em;

  label {
    animation: ${css`
      ${fadeIn} ${config.animation.loadingLogoDuration}ms forwards
    `};
    color: ${(props) => props.theme.INPUT_PLACEHOLDER_COLOR};
    text-align: left;
    display: block;
    margin-bottom: 0.5em;
  }

  input {
    font-family: "Bungee Hairline", sans-serif;
    width: 100%;
    padding: 0.5em;
    background: none;
    border: none;
    font-size: 16px;
    color: ${(props) => props.theme.FONT_COLOR};
    border-bottom: 1px solid ${(props) => props.theme.FONT_COLOR};
    border-radius: 0px;

    &:focus {
      background: ${({ theme }) => theme.GREY};
    }
    &:focus-visible {
      outline: none;
    }

    &:: placeholder {
      color: ${({ theme }) => theme.INPUT_PLACEHOLDER_COLOR};
    }
    &::-ms-input-placeholder {
      color: ${({ theme }) => theme.INPUT_PLACEHOLDER_COLOR};
    }
  }

  div {
    font-family: "Bungee Inline";
    text-align: right;
    color: red;
    margin-top: 0.5em;
  }

  button {
    background: red;
  }
`;

export const TextAreaField = styled.textarea`
  font-family: "Bungee Hairline", sans-serif;
  width: 100%;
  padding: 0.5em;
  color: ${(props) => props.theme.FONT_COLOR};
  background: none;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.FONT_COLOR};
  border-radius: 0px;
  resize: none; /* Prevent resizing */
  font-size: 16px;

  &:focus {
    background: ${({ theme }) => theme.GREY};
  }
  &:focus-visible {
    outline: none;
  }

  &:: placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.INPUT_PLACEHOLDER_COLOR};
  }
  &::-ms-input-placeholder {
    color: ${({ theme }) => theme.INPUT_PLACEHOLDER_COLOR};
  }
`;

// About page
export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const TextContainer = styled.div`
  flex: 2;
  p {
    text-align: justify;
  }
`;

// Contact Page
export const ContactLinks = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

// Cookie consent
export const CookieConsentWrapper = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;
  width: 100%;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.BACKGROUND_COLOR}86;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ModalContainer = styled.div`
  background: ${(props) => props.theme.BACKGROUND_COLOR};
  box-shadow: 1px 1px 30px ${(props) => props.theme.FONT_COLOR}36;
  color: ${(props) => props.theme.FONT_COLOR};
  padding: 30px;
  border-radius: 10px;

  p,
  label {
    font-size: 12px;
  }
`;

// Footer
export const FooterWrapper = styled.div`
  position: relative;

  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
`;
// Audio player
export const AudioPlayerWrapper = styled.div<{ $isplaying: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: fixed;
  z-index: 10;
  bottom: 40px;
  right: 60px;
  border-radius: 50px;
  transition: all ${config.animation.styleAnimationDuration}ms ease-in-out;

  // Apply animation if isPlaying is true
  button {
    ${(props) =>
      props.$isplaying === "true" &&
      css`
        animation: ${createBoxFlashing(props.theme.FONT_COLOR)} ${config.animation.loadingLogoDuration}ms infinite;

        div {
          border: none;
        }
      `}
  }

  @media (max-width: 768px) {
    position: initial;
  }
`;

export const AudioPlayerContainer = styled.div<{ $isplaying: string }>`
  display: flex;
  align-items: center;
  gap: 20px;
  height: 50px;
  padding: 0px 20px;
  font-size: 16px;
  cursor: pointer;
  min-width: ${(props) => (props.$isplaying === "true" ? "140px" : "10px")};
  max-width: ${(props) => (props.$isplaying === "true" ? "200px" : "64px")};
  overflow: ${(props) => (props.$isplaying === "true" ? "initial" : "hidden")};
  background: ${(props) => props.theme.BACKGROUND_COLOR}c2;
  color: ${(props) => props.theme.FONT_COLOR};
  border: 1px solid ${(props) => props.theme.FONT_COLOR};
  border-radius: 50px;
  transition: all ${config.animation.styleAnimationDuration}ms ease-in-out;

  ${(props) =>
    props.$isplaying === "true" &&
    css`
      animation: ${createBoxFlashing(props.theme.FONT_COLOR)} ${config.animation.loadingLogoDuration}ms infinite;

      div {
        border: none;
      }
    `}

  .music-icon {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
      opacity ${config.animation.styleAnimationDuration}ms ease,
      color ${config.animation.styleAnimationDuration}ms ease;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      color: ${(props) => props.theme.MAIN_COLOR};
    }
  }

  svg {
    transition:
      opacity 0.3s ease,
      color 0.3s ease;
  }

  .volume-control {
    position: relative;
    display: inline-block;

    .volume-slider {
      position: absolute;
      bottom: 29px;
      right: -3px;

      input[type="range"] {
        -webkit-appearance: none;
        width: 89px;
        height: 3px;
        background: ${(props) => props.theme.FONT_COLOR};
        border-radius: 5px;
        outline: none;
        transition: opacity 0.2s ease-in-out;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 15px;
          height: 15px;
          background: ${(props) => props.theme.MAIN_COLOR};
          border-radius: 50%;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 15px;
          height: 15px;
          background: ${(props) => props.theme.MAIN_COLOR};
          border-radius: 50%;
          cursor: pointer;
        }

        &::-ms-thumb {
          width: 15px;
          height: 15px;
          background: ${(props) => props.theme.MAIN_COLOR};
          border-radius: 50%;
          cursor: pointer;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    height: fit-content;
    padding: 20px 13px;
    min-width: initial;
    max-width: initial;

    .volume-control .volume-slider {
      bottom: 0;
      right: 0;
      transform: rotate(-90deg);
      padding: 15px 5px;
      border-radius: 10px;
      background: ${(props) => props.theme.FONT_COLOR}4f;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

// FAQ
export const FAQWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FAQTitle = styled.div`
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;

  img {
    max-width: 65%;
  }

  img:first-child {
    border-bottom: 1px solid ${(props) => props.theme.FONT_COLOR};
  }

  img:last-child {
    border-top: 1px solid ${(props) => props.theme.FONT_COLOR};
  }

  @media (max-width: 768px) {
    padding: 20px;

    img {
      max-width: 70%;
    }
  }
`;

export const FAQItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid ${(props) => props.theme.FONT_COLOR};
`;

export const FAQItemQuestion = styled.div`
  cursor: pointer;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
  font-size: 20px;
  transition: ${config.animation.styleAnimationDuration}ms;

  &:hover {
    animation: ${(props) => css`
      ${colorAnimation(props.theme.FONT_COLOR, props.theme.MAIN_COLOR)} ${config.animation
        .loadingLogoDuration}ms ease-in-out infinite;
    `};

    svg {
      transform: rotate(45deg) !important;
    }
  }
`;
