import { config } from "../config/config";
// import { ICredentials } from "../store/AuthStore";
import { IContactPayload } from "./APITypes";

export const STATUS_CODE_UNAUTHORIZED = 401;
export const STATUS_CODE_CONFLICT = 409;

// const getAuthHeaders = () => ({
//     Authorization: `Bearer ${authStore.credentials && authStore.credentials.access_token}`,
// });

// const handleUnauthorizedError = (error: any): any => {
//     if (error.statusCode && error.statusCode === STATUS_CODE_UNAUTHORIZED) {
//         authStore.logout();
//     }
// };

const validateResponse = (resp: Response): void => {
  if (!resp.ok) {
    console.error("Error response:", resp);
    throw {
      statusCode: resp.status,
      statusText: resp.statusText,
      response: resp.json(),
    };
  }
};

export const API = {
  // ----------------------
  // AUTH API
  // ----------------------

  // async loginWithPassword(options: { username: string; password: string }): Promise<ICredentials> {
  //     try {
  //         const response = await fetch(`${config.backend.apiBaseUrl}/api/v1/auth/login`, {
  //             method: "POST",
  //             headers: {
  //                 "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify({
  //                 username: options.username,
  //                 password: options.password,
  //             }),
  //         });

  //         validateResponse(response);

  //         return response.json();
  //     } catch (error) {
  //         handleUnauthorizedError(error);
  //         throw error;
  //     }
  // },

  async postContact(payload: IContactPayload): Promise<IContactPayload> {
    try {
      const apiKey = process.env.REACT_APP_API_KEY;

      // Throw an error if the API key is not defined
      if (!apiKey) {
        throw new Error("API key is missing");
      }

      const response = await fetch(`${config.backend.apiBaseUrl}/api/v1/contact`, {
        headers: {
          "Content-Type": "application/json",
          "X-API-KEY": apiKey,
        },
        method: "POST",
        body: JSON.stringify(payload),
      });

      validateResponse(response);

      return response.json();
    } catch (error) {
      console.error("Error posting contact:", error);
      throw error;
    }
  },
};
