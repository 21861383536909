import React, { useEffect, useState } from "react";

const Cursor: React.FC = () => {
  const [cursorType, setCursorType] = useState("default");

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const cursor = document.querySelector(".custom-cursor") as HTMLElement;
      if (cursor) {
        cursor.style.left = `${event.pageX}px`; // Use pageX to account for scroll position
        cursor.style.top = `${event.pageY}px`; // Use pageY to account for scroll position
      }
    };

    const handleMouseOver = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        target.tagName === "A" ||
        target.tagName === "BUTTON" ||
        target.closest("a") ||
        target.closest("button") ||
        target.closest(".cursor-pointer") ||
        target.closest(".music-icon") ||
        target.closest(".project-thumbnail")
      ) {
        setCursorType("pointer");
      } else if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
        setCursorType("input");
      } else if (target.classList.contains("projectContainer")) {
        setCursorType("close");
      } else {
        setCursorType("default");
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseover", handleMouseOver);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseover", handleMouseOver);
    };
  }, []);

  return <div className={`custom-cursor ${cursorType}`} />;
};

export default Cursor;
