import { useTranslation } from "react-i18next";
import { PromoContainer } from "../../util/Styles";
import { EmailButton } from "./EmailButton";

export const Promo = () => {
  const { t } = useTranslation();

  return (
    <PromoContainer className="responsive-flex">
      <h2>
        <strong>
          {t("screen.promo.bannerText1")}
          <br />
          {t("screen.promo.bannerText2")}
        </strong>
      </h2>
      <EmailButton />
    </PromoContainer>
  );
};
