export const lightTheme = {
  BACKGROUND_COLOR: "#FFFFFF",
  BACKGROUND_COLOR_REVERSED: "#1a1a1a",
  MENU_BACKGROUND_XS: "linear-gradient(0deg, #e4e4e4fa, #ffffffd4)",
  MAIN_COLOR: "#a0432c",
  FONT_COLOR: "#000000",
  INPUT_BORDER_COLOR: "#000",
  INACTIVE: "#000",
  GREY: "#e8e8e8",
  GREYS_LIGHT: "#000",
  ERROR_COLOR: "#000",
  INPUT_PLACEHOLDER_COLOR: "#000",
  BLACK: "#000",
  HOVER: "#000",
  INACTIVES_LIGHT: "#000",
  WHITE: "#fff",
  BACKGROUND_SHADE_3: "#000",
  BACKGROUND_SHADE_2: "#000",
  BACKGROUND_LIGHT_COLOR: "#000",
  WARNING: "#000",
  CURSOR_DEFAULT_COLOR: "#00000054",
  CURSOR_POINTER_COLOR: "#ff330094",
  CURSOR_INPUT_COLOR: "#ff330094",
  MAX_SCREEN_WIDTH: 1380,
};

export const darkTheme = {
  BACKGROUND_COLOR: "#1a1a1a",
  BACKGROUND_COLOR_REVERSED: "#ffffff",
  MENU_BACKGROUND_XS: "linear-gradient(0deg, #808080fa, #1a1a1ae0)",
  MAIN_COLOR: "#a0432c",
  FONT_COLOR: "#ffffff",
  INPUT_BORDER_COLOR: "#00ffee",
  INACTIVE: "#00ffee",
  GREY: "#262626",
  GREYS_LIGHT: "#00ffee",
  ERROR_COLOR: "#00ffee",
  INPUT_PLACEHOLDER_COLOR: "#9b9b9b",
  BLACK: "#00ffee",
  HOVER: "#00ffee",
  INACTIVES_LIGHT: "#00ffee",
  WHITE: "#fff",
  BACKGROUND_SHADE_3: "#00ffee",
  BACKGROUND_SHADE_2: "#00ffee",
  BACKGROUND_LIGHT_COLOR: "#00ffee",
  WARNING: "#00ffee",
  CURSOR_DEFAULT_COLOR: "#ffffff54",
  CURSOR_POINTER_COLOR: "#ff330094",
  CURSOR_INPUT_COLOR: "#ff330094",
  MAX_SCREEN_WIDTH: 1380,
};
