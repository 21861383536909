import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, PortfolioPromo, PortfolioPromoWrapper, PromoContainer } from "../../util/Styles";
import { Routes } from "../../util/Routes";
import musicIcon01 from "../../assets/images/icons/musicIcon01.png";
import musicIcon02 from "../../assets/images/icons/musicIcon02.png";
import musicIcon03 from "../../assets/images/icons/musicIcon03.png";
import musicIcon04 from "../../assets/images/icons/musicIcon04.png";
import musicIcon05 from "../../assets/images/icons/musicIcon05.png";
import musicIcon06 from "../../assets/images/icons/musicIcon06.png";
import musicIcon07 from "../../assets/images/icons/musicIcon07.png";
import musicIcon08 from "../../assets/images/icons/musicIcon08.png";
import { useTranslation } from "react-i18next";
import { CreateNavUrl } from "../../util/Utils";

const promoIcons = [
  musicIcon01,
  musicIcon02,
  musicIcon03,
  musicIcon04,
  musicIcon05,
  musicIcon06,
  musicIcon07,
  musicIcon08,
];

export const PortfolioClickBait = () => {
  const { t } = useTranslation();

  const promoTexts = [
    t("screen.promo.text1"),
    t("screen.promo.text2"),
    t("screen.promo.text3"),
    t("screen.promo.text4"),
    t("screen.promo.text5"),
    t("screen.promo.text6"),
    t("screen.promo.text7"),
    t("screen.promo.text8"),
  ];

  const randomText = promoTexts[Math.floor(Math.random() * promoTexts.length)];
  const randomIcon = promoIcons[Math.floor(Math.random() * promoIcons.length)];

  return (
    <PortfolioPromoWrapper>
      <PortfolioPromo to={CreateNavUrl(Routes.CONTACT)}>
        <PromoContainer style={{ display: "flex", gap: 5, alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <img src={randomIcon} alt={"musical-icon"} />
            <h3 className="font-hairline">{randomText}</h3>
          </div>
          <IconButton>
            <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 20 }} />
          </IconButton>
        </PromoContainer>
      </PortfolioPromo>
    </PortfolioPromoWrapper>
  );
};
